// CallBoard.tsx
import "/node_modules/augmented-ui/augmented-ui.min.css"; // Must be imported before other styles

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from '../utils/auth';
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Slider } from "primereact/slider";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { MeterGroup } from "primereact/metergroup";
import { ToggleButton } from 'primereact/togglebutton';
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";

import { Tag } from "primereact/tag";
import config from "../appsettings.json";
import { uploadCsv } from "../utils/UploadUtils";
import CallMetricsHUD from './CallMetricsHUD';

// import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/themes/mdc-dark-indigo/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import "../styles/global.css";
import "../styles/App.css";

import { Panel } from "primereact/panel";
import { Fieldset } from "primereact/fieldset";
import { Sidebar } from "primereact/sidebar";

import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { RadioButton } from "primereact/radiobutton";
import ConsoleHeader from "./ConsoleHeader";
import LogoutButton from "./LogoutButton";
import GoogleCalendarConnect from "./GoogleCalendarConnect";
import AppFooter from "./AppFooter";
import { FloatLabel } from "primereact/floatlabel";
import ConversationLogs from './ConversationLogs';

const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}
const FRONTEND_VERSION = config.FrontendVersion;

interface CallBoardProps {
  tenantId: string | null;
}

export default function CallBoard({ tenantId }: { tenantId: string | null }): JSX.Element {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [iconsRegistered, setIconsRegistered] = useState(false);
  const [newCallStarted, setNewCallStarted] = useState(false);
  const [acsPhoneNumberE164, setAcsPhoneNumberE164] = useState<string>("+14808607444");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [useDemoMode, setUseDemoMode] = useState(false);
  const [useDemoIvrMode, setUseDemoIvrMode] = useState(false);
  const [callListGroupName, setCallListGroupName] = useState<string>("");
  const [answerPromptSystemTemplate, setAnswerPromptSystemTemplate] =
    useState<string>("");
  const [selectedVoice, setSelectedVoice] =
    useState<string>("en-US-KaiNeural"); //en-US-KaiNeural (Works) //en-US-AndrewMultilingualNeural (8565) // en-US-AndrewNeural (8565)
  const [userId, setUserId] = useState<string>("");
  const [threadId, setThreadId] = useState<string>("");
  const [aiName, setAiName] = useState<string>("Andrew");
  const [enableStreamedResponses, setEnableStreamedResponses] =
    useState<boolean>(true);
  const [targetCompanyName, setTargetCompanyName] =
    useState<string>("Sound Construction");
  const [isCalling, setIsCalling] = useState(false);
  const [azureOpenAiDeploymentName, setAzureOpenAiDeploymentName] =
    useState<string>("chatgpt-deployment-16k");
  const [waitForHelloMs, setWaitForHelloMs] = useState<number>(60000);
  const [initialSilenceTimeoutMs, setInitialSilenceTimeoutMs] =
    useState<number>(20000);
  const [speechPauseTimeoutMs, setSpeechPauseTimeoutMs] = useState<number>(500);
  const [visible, setVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const isFirstLoad = useRef(true);

  const [showGoogleCalendarDialog, setShowGoogleCalendarDialog] = useState(false);

  const UPLOADCSV_API = `${BASE_URL}/api/uploadcsv?tenantId=${tenantId}`;

  const toastRef = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);

  const [uploadErrorHandleOption, setUploadErrorHandleOption] = useState('AbortOnInvalidFieldData');

  const handleCallListGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCallListGroupName(event.target.value);
  };

  const handleUploadCsv = async (event: { files: any[] }) => {
    console.log("handleUploadCsv called");
    setLoading(true);

    if (!tenantId) {
      console.error("Tenant ID is not available");
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tenant ID is required for CSV upload',
        life: 3000
      });
      setLoading(false);
      return;
    }

    if (event.files.length) {
      const file = event.files[0];
      console.log("Uploading file:", file);
      console.log("Tenant ID:", tenantId);
      console.log("Call List Group Name:", callListGroupName || "");
      console.log("Upload Error Handle Option:", uploadErrorHandleOption);

      try {
        const getAccessToken = async () => {
          return await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: "openid profile email"
            }
          });
        };

        const uploadResult = await uploadCsv(file, tenantId, callListGroupName || null, uploadErrorHandleOption, getAccessToken);

        console.log("Upload Result:", uploadResult);

        if (uploadResult.success) {
          console.log("Showing success toast");
          toastRef.current?.show({
            sticky: true,
            severity: "success",
            summary: "Success",
            detail: `CSV upload results: ${uploadResult.result.message}`,
          });

          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }
        } else {
          console.log("Showing error toast");
          toastRef.current?.show({
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: `Upload failed: ${uploadResult.errorMessage}`,
          });
        }
      } catch (err) {
        console.error("Error during file upload:", err);
        toastRef.current?.show({
          sticky: true,
          severity: "error",
          summary: "Error",
          detail: `Upload failed: ${(err as Error).message}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No file selected for upload");
      setLoading(false);
    }
  };

  // Custom File Uploader /////////////////////////////////////////////////

  const [totalSize, setTotalSize] = useState(0);

  const onTemplateSelect = (e: { files: any; }) => {
    setTotalSize(0);
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: { className: any; chooseButton: any; uploadButton: any; cancelButton: any; }) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 100000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 5 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column bg-transparent">
        <span className="m-0 p-0">
          Drag and Drop .csv Here and Press Upload
        </span>
        <i className="pi pi-address-book mt-0 py-0 vertical-align-super" style={{ fontSize: '3em', borderRadius: '50%', color: 'var(--sublte-image-color)' }}></i>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-address-book', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  //////////////////////////////////////////////////////////////////////////

  const displayCallBoard = (): JSX.Element => {
    return (
      <div className="bg-center bg-no-repeat">
        <ConsoleHeader />
        <Toast ref={toastRef} />
        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
        <div className="home-container bg-center bg-no-repeat sm:block md:flex flex-wrap  align-items-center justify-content-center gap-1">
          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tl-clip tr-clip-x br-clip-x br-clip-inset b-clip-x bl-clip border" className="common-aug-mixin common-aug-tl-mixin">
              <Card className="p-3 card bg-transparent h-30rem w-28rem" title="Call HUD">

                <CallMetricsHUD tenantId={tenantId} />

              </Card>
            </div>
          </div>
          {/* Insert ConversationLogs component here */}
          <ConversationLogs tenantId={tenantId} />
          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tl-clip bl-clip br-clip tr-clip-x tr-clip-inset t-clip-x border" className="common-aug-mixin common-aug-bl-mixin">
              <Card title="Upload CSV" className="p-3 card bg-transparent h-30rem">
                <label className="ml-1">On invalid field data import errors?</label>
                <div className="card flex justify-content-left mt-2">
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton inputId="AbortOnInvalidFieldData" name="AbortOnInvalidFieldData" value="AbortOnInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'AbortOnInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle1" className="ml-2">Abort Upload</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton inputId="SkipRowsWithInvalidFieldData" name="SkipRowsWithInvalidFieldData" value="SkipRowsWithInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'SkipRowsWithInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle2" className="ml-2">Skip Row</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton inputId="ExcludeInvalidFieldData" name="ExcludeInvalidFieldData" value="ExcludeInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'ExcludeInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle3" className="ml-2">Exclude Field</label>
                    </div>
                  </div>
                </div>
                <InputText className="mt-3"
                  type="text"
                  placeholder="Call List Group Name"
                  value={callListGroupName}
                  onChange={handleCallListGroupNameChange}
                />
                <FileUpload
                  ref={fileUploadRef}
                  name="csvupload"
                  url={UPLOADCSV_API}
                  accept=".csv"
                  maxFileSize={5000000}
                  uploadHandler={handleUploadCsv}
                  customUpload
                  multiple={false}
                  disabled={loading || !tenantId} // Disable if loading or no tenantId
                  onSelect={onTemplateSelect}
                  onError={onTemplateClear}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                  uploadOptions={uploadOptions}
                  cancelOptions={cancelOptions}
                  className="bg-black-alpha-10 mt-3"
                />
              </Card>
            </div>
          </div>
          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tr-clip bl-clip br-clip tr-clip tl-clip-x tl-clip-inset b-clip border" className="common-aug-mixin common-aug-br-mixin">
              <Card className="p-3 card bg-transparent h-30rem"
                title={
                  <div className="card flex justify-content-end text-right">Google® Calendar Integration</div>
                }>
                <div className="flex w-26rem">
                  <GoogleCalendarConnect isEmbedded={true} />
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div data-augmented-ui="tl-clip br-clip both"></div>
        <div data-augmented-ui="all-triangle-down border"></div>
        <AppFooter />
      </div>
    );
  };

  return displayCallBoard();
};
